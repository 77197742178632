export default [
  {
    title: 'I tuoi carichi',
    route: 'cargo-list',
    icon: 'GlobeIcon',
  },
  {
    title: 'Conf. Sequenze',
    icon: 'ToolIcon',
    route: 'configurazione-carichi',
  },
  {
    title: 'Software',
    route: 'software',
    icon: 'DownloadIcon',
  },
]
